export const baseUrl: string = process.env.NODE_ENV === "production"
                               ? window.location.protocol + "//" + window.location.host
                               : "https://teec2-odzob-dev.informationgrid.io"
const productieToken = "yMbuGOoz+9yKvYhNi1OfbA=="
const accToken = "XA3T/IqaBrn9mo+rKsw/cw=="
const testToken = "iACU4d5q12mONw4+Ivwi1Q=="
export const AuthToken: string = testToken//"XA3T/IqaBrn9mo+rKsw/cw=="//

export const REST: string = "/ig/rest"

export const IGStyle = {
    width: "100%"
}
export const Delete: string = "Delete"

//<editor-fold desc="Commands">

//<editor-fold desc="Pool">

export const Pool: string = "Pool"
export const PoolDetail: string = "PoolDetail"
export const PoolUrl: string = "/aggregate/Pool/"

export const AddPool: string = "AddPool"
export const UpdatePool: string = "UpdatePool"

export const RegisterPoolCommand: string = "/command/RegisterPool"
export const UpdatePoolCommand: string = "/command/UpdatePool"
export const DeactivatePoolCommand: string = "/command/DeactivatePool"

//</editor-fold>

//<editor-fold desc="Compliance Schedule">

export const ComplianceSchedule: string = "ComplianceSchedule"
export const ComplianceScheduleDetail: string = "ComplianceScheduleDetail"
export const ComplianceScheduleUrl: string = "/aggregate/ComplianceSchedule"

export const AddComplianceSchedule: string = "AddComplianceSchedule"
export const UpdateComplianceSchedule: string = "UpdateComplianceSchedule"

export const RegisterComplianceScheduleCommand: string = "/command/AddComplianceSchedule"
export const UpdateComplianceScheduleCommand: string = "/command/UpdateComplianceSchedule"
export const DeactivateComplianceScheduleCommand: string = "/command/DeactivateComplianceSchedule"

//</editor-fold>

//<editor-fold desc="Aerosol">
export const Aerosol: string = "Aerosol"
export const AerosolDetail: string = "AerosolDetail"

export const AddAerosol: string = "AddAerosol"
export const UpdateAerosol: string = "UpdateAerosol"

export const AddAerosolCommand: string = "/command/AddAerosolElement"
export const UpdateAerosolCommand: string = "/command/UpdateAerosolElement"
export const DeactivateAerosolCommand: string = "/command/DeactivateAerosolElement"

//</editor-fold>

//<editor-fold desc="Adres">
export const Adres: string = "Adres"
export const AdresDetail: string = "AdresDetail"

export const AddAdres: string = "AddAdres"
export const UpdateAdres: string = "UpdateAdres"

export const AddAddressCommand: string = "/command/AddAddress"
export const UpdateAddressCommand: string = "/command/UpdateAddress"

//</editor-fold>

//<editor-fold desc="Bassin">
export const Bassin: string = "Bassin"
export const BassinDetail: string = "BassinDetail"

export const AddBassin: string = "AddBassin"
export const UpdateBassin: string = "UpdateBassin"

export const AddBassinCommand: string = "/command/AddBassin"
export const UpdateBassinCommand: string = "/command/UpdateBassin"
export const DeactivateBassinCommand: string = "/command/DeactivateBassin"

//</editor-fold>

//<editor-fold desc="Contact">
export const Contact: string = "Contact"
export const ContactDetail: string = "ContactDetail"

export const AddContact: string = "AddContact"
export const UpdateContact: string = "UpdateContact"

export const AddContactCommand: string = "/command/AddContact"
export const UpdateContactCommand: string = "/command/UpdateContact"
export const DeactivateContactCommand: string = "/command/DeactivateContact"

//</editor-fold>

//<editor-fold desc="Installatie">
export const Installatie: string = "Installatie"
export const InstallatieDetail: string = "InstallatieDetail"
export const AddInstallatie: string = "AddInstallatie"
export const UpdateInstallatie: string = "UpdateInstallatie"

export const AddInstallatieCommand: string = "/command/AddSystem"
export const UpdateInstallatieCommand: string = "/command/UpdateSystem"
export const DeactivateInstallatieCommand: string = "/command/DeactivateSystem"

//</editor-fold>

//<editor-fold desc="Contact">
export const Measurement: string = "Measurement"
export const MeasurementDetail: string = "MeasurementDetail"

export const AddMeasurement: string = "AddMeasurement"
export const UpdateMeasurement: string = "UpdateMeasurement"

export const AddMeasurementCommand: string = "/command/AddMeasurement"
export const UpdateMeasurementCommand: string = "/command/UpdateMeasurement"
export const DeactivateMeasurementCommand: string = "/command/DeactivateMeasurement"

//</editor-fold>
//<editor-fold desc="Controle">
//  export const Controle: string = "Controle"
//  export const ControleDetail: string = "ControleDetail"

export const AddControle: string = "AddControle"
// export const UpdateMeasurement: string = "UpdateMeasurement"

export const AddControleCommand: string = "/command/PerformCheck"
// export const UpdateMeasurementCommand: string = "/command/UpdateMeasurement"
// export const DeactivateMeasurementCommand: string = "/command/DeactivateMeasurement"

//</editor-fold>
//</editor-fold>